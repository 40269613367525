<template>
  <div class="container">
    <div class="row justify-content-center" v-if="$route.params.status === '000'">
      <div class="col-6 text-center">
        <div class="savers-card">
          <div class="savers-card-body">
            <img style="height: 60px; width: 60px" src="../../assets/payment_success.png" />
            <h2 v-if="$route.params.message === 'Cash on Delivery'">Order Cash on Delivery Confirm.</h2>
            <h2 v-else>Payment Successful.</h2>
            <p>Thank you for choosing Eurasiasupplies.</p>
            <router-link to="/" tag="button">Continue Shopping ..</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-if="$route.params.status === '111'">
      <div class="col-6 text-center">
        <div class="savers-card">
          <div class="savers-card-body">
            <img style="height: 60px; width: 60px" src="../../assets/payment_cancel.png" />
            <h2>Cancel Payment!</h2>
            <p>Thank you for choosing Eurasiasupplies.</p>
            <router-link to="/" tag="button">Continue Shopping ..</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-if="$route.params.status === '222'">
      <div class="col-6 text-center">
        <div class="savers-card">
          <div class="savers-card-body">
            <img style="height: 60px; width: 60px" src="../../assets/payment_fail.png" />
            <h2>Fail Payment!</h2>
            <p>Payment fail due to insufficient balance.</p>
            <router-link to="/" tag="button">Continue Shopping ..</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

  data() {
    return {
      ecommerce: {
        items: {
          item_name: '',
          item_id: '',
          price: '',
          item_brand: '',
          item_category: '',
          item_variant: '',
          quantity: '',
        }
      }
    }
  },
  watch: {
    $route() {
      // console.log("Watch Status: ", this.$route.params.status);
      // console.log("Watch Purchase Items: ", this.getPurchaseItems);
    }
  },
  computed: {
    ...mapGetters([
      "getPurchaseItems",
      "shippingCharge"
    ]),
  },
  mounted() {
    if (this.$route.params.status === '000') {
      // console.log("Status: ", this.$route.params.status);
      // console.log("Purchase Items: ", this.getPurchaseItems);
      let allData = []
      let mData = {
        item_name: '',
        item_id: '',
        price: '',
        item_brand: '',
        item_category: '',
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_variant: '',
        item_list_name: '',
        item_list_id: '',
        index: 0,
        quantity: '',
      }
      this.getPurchaseItems.cartData.forEach(item => {
        mData.item_name = item.name
        mData.item_id = item.id
        mData.price = item.unitPrice
        mData.item_brand = item.brand_id
        mData.quantity = item.qty
        allData.push(mData)
      })
      window.fbq('track', 'Purchase', { value: 100, currency: 'BDT', contents: allData })
    }
  }
}

</script>
<style>
.savers-card {
  background: #f6f6f6;
  padding: 25px 0px;
  margin: 25px 0px;
  border-radius: 5px;
}

.savers-card-body button {
  border: none;
  background: #65c03a;
  padding: 5px 15px;
  color: #fff;
  border-radius: 4px;
}
</style>